<template>
  <div>
    <PageHeader title="Frequently Asked Questions"></PageHeader>

    <div class="text-body-1 mb-10">
  Please feel free to reach out to us if you do not find the answer you need below. Call 214-997-6005 or email register@thetitlegirl.com.
    </div>
    <v-row class="text-center">
      <v-col cols="12">
        <iframe
          src="https://player.vimeo.com/video/543795257"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
<!-- 
        <iframe
          src="https://player.vimeo.com/video/543795257"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe> -->
      </v-col>
    </v-row>
    <div>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, x) in faq" :key="x">
          <v-expansion-panel-header class="text-h5">{{ item.question }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="text-body-1 mb-6">
              {{ item.answer }}
              <ol class="mt-6" v-if="item.subcontent">
                <li v-for="(sub, y) in item.subcontent" :key="y">{{ sub }}</li>
              </ol>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  name: 'faq',
  directives: {},
  components: {
    PageHeader: () => import('@/components/app/PageHeader'),
  },
  created() {},
  data: () => ({
    faq: [
      {
        question: 'Which Documents do I need to ship to The Title Girl?',
        answer:
          'Below is a list of all the necessary documents we need to register your Tesla. Please note, we must have the ORIGINAL signed documents shipped to us to submit to the County on your behalf.',
        subcontent: [
          'Motor Vehicle Purchase Agreement or Bill of Sale',
          'Manufacturer Certificate of Origin for new vehicles (Sign the back of the MCO as the Purchaser, at the bottom of the page above your typed name) or Title for a used vehicle (If signing a Title, sign everywhere it lists Buyer) If unsure, please contact us at 214-997-6005 or email a copy of the title (front and back) to register@thetitlegirl.com.',
          'Application for Texas Title/Registration (Form # 130-U)',
          'Odometer Disclosure',
          'Original Texas Department of Public Safety Vehicle Inspection Report – look in the glove box of your vehicle. Request from Tesla if not provided.',
          'Limited Power of Attorney (VTR-271) – arrives in an email from The Title Girl that lists your cost breakdown and has this POA attached. The FedEx label is also attached to these emails.',
          'Copy of the Driver’s License for each person listed on your application.',
          'Copy of your Vehicle Insurance Card showing Texas Liability coverage. Insurance card must list applicants name (or business) as it reads on the Application for Texas Title, plus the Tesla Model and VIN#. Insurance cannot be expired.',
          'Payment of all sales tax and fees. (If you choose to make an ACH payment you can click on Pay Now in this portal to make that ACH payment. A cashier check/money order (if not choosing ACH) made payable to The Title Girl is the other option. We will pay the county for you.) ',
          'Trade-In Annex – only required if you traded in a vehicle at time of purchase.',
        ],
      },
      {
        question: 'Where and how do I ship my documents?',
        answer: 'The Title Girl provides a prepaid FedEx shipping label through email after you have submitted your Bill of Sale. You can use this to ship all your original documents to us. If you cannot locate the label, please email register@thetitlegirl.com to request a new one.',
      },
      {
        question: 'Where do I sign the Title or Certificate of Origin?',
        answer:
          'If you are not sure where to sign your Title, feel free to email us a picture of the front and back to register@thetitlegirl.com so one of our experts can advise you. For the Certificate of Origin, sign as the purchaser on the back in the “Odometer Disclosure” section which is at the bottom. Please note, each person listed on the application needs to sign the Certificate of Origin or Title.',
      },
      {
        question: 'What is the Limited Power of Attorney (VTR-271)? ',
        answer: 'You give The Title Girl limited power of attorney so that we may make any necessary corrections to your paperwork and to give us permission to submit the application for registration on your behalf. The form is provided by The Title Girl and is attached to your cost breakdown email. If you do not see the attachment, please email register@thetitlegirl.com to have it sent to you.',
      },
      {
        question: 'Do I need to send the original Title/Certificate of Origin and other originals?',
        answer: 'Yes, we need the original title/certificate of origin to submit to the State on your behalf. Your Texas Title will be sent to you or your lien holder about 3-4 weeks after the registration has been completed. All documents you sign, the original must be sent to The Title Girl. Plus, the Vehicle Inspection Report must be original and signed by the inspector. There are 5-6 documents that need to be signed and the original sent to The Title Girl using the pre-paid FedEx label emailed to you. List of documents: MCO or Title, Application for Texas Title (130-U), Odometer Disclosure, Vehicle Inspection Report, Limited Power of Attorney (VTR-271) and Trade-In Annex (if applicable).',
},
      {
        question: 'I do not have an inspection report, what should I do?',
        answer:
'Tesla provides this document to you and places it in the glovebox of your Tesla. If there is no inspection in the glovebox, you can either contact Tesla and have them send an original version of the inspection report (no photocopies accepted by the state) or you can go to any State Inspection Facility and have the vehicle inspected yourself.',
},
      {
        question: 'Why do I need to send the original documents? Will I get them back?',
        answer:
'We need the original documents and signatures as required by the State to process your registration. The State processes the paperwork and does not return it back to us. What you get in return are your license plates, registration sticker, as well as State-issued Title. Feel free to make copies of the documents for your own records if you would like. ',
},
      {
        question: 'What if I am registering my vehicle under a business?',
        answer: 'If your business name is listed as well as your name, you will need to sign twice on all necessary documents. Your business name will need to be listed on your insurance card. We will need a letter on your company letterhead stating that you are the sole representative and can sign on behalf of the company with your signature on it. If you would like a letter template, please email register@thetitlegirl.com to request it. ',
      },
      {
        question: 'Why are there late fees included in my cost breakdown? ',
        answer: 'We charge the 30-day late fee precautionarily in case we do not receive your payment or documents within the 30-day deadline for the County. This will eliminate any delays with the County for insufficient funds. If the late fee is not used at the County, it will be refunded to you by check with the delivery of your plates and sticker. NOTE: County Tax Offices charge an additional 60-day late fee from the date of sale of your vehicle. We will contact you to collect this additional fee prior to processing if necessary. ',
      },
      {
        question: 'When does the County start charging late fees?',
        answer:
          'The County charges late fees at both 30 days and 60 days after the date of sale for your vehicle. The percentages are as follows:  <br> 30 days after date of sale – 5% fee on sales tax + $25 delinquent transfer fee  <br> 60 days after date of sale – additional 10% fee on sales tax + additional $25 delinquent transfer fee  <br> *The County will charge the $25 delinquent transfer fee every 30 days past date of sale, up to $200. ',
      },
      {
        question: 'How will I get my refund? ',
        answer:
'If a refund is owed, the refund check will be included in the packaged with your completed registration.',
},
       {
        question: 'Why is my registration cost so high?',
        answer:
'Tesla does not charge sales tax when you purchase the vehicle. Therefore, the sales tax (6.25% of purchase price) plus county fees, are due at the time of registration. You can see this information reflected on your Bill of Sale.',
},
       {
        question: 'What does your service fee include?',
        answer:
'When you use The Title Girl to process your vehicle registration you pay a service fee. We audit your paperwork, process everything at the County, solve any issues that may arise, and offer customer support throughout the process. Most importantly – skip the line - we save you trips you would have to take to the Tax Office.  ',
},

       {
        question: 'Do you accept credit cards?',
        answer:
'No, due to high transaction amounts, it is not feasible to accept credit cards. Please click on the “Payment Methods” button to see all your options for payment. If you wish to pay electronically, you can do so through our portal by making an ACH payment. Click on PAY NOW.  ',
},
       {
        question: 'How long will it take to get my license plates, registration sticker and title? ',
        answer:

'Please be advised turnaround times vary greatly depending on the county. If you would like an estimate on a specific county, reach out to us by email at register@thetitlegirl.com or call us at 214.997.6005 for a more specific estimate. Title transfer happens 3-4 weeks after registration is complete. The state of Texas will send your new Texas title to the owner of the vehicle separately via US Mail. ',
},

       {
        question: 'What is a white slip?',
        answer:
'This is your registration receipt that also includes the registration sticker for your windshield on it. It breaks down the cost paid to the county, which will equate to the costs that you paid to us minus our service fee. ',
},

       {
        question: 'What if I have existing plates or want to order custom plates? ',
        answer:
'If you have plates you would like to transfer to your Tesla, please send a picture of the existing plates either printed out with your documents or through email to register@thetitlegirl.com. If you would like custom plates, please place your order on www.myplates.com and email us the confirmation you get from them. We will then be able to register your vehicle to the custom plates. Please note that the County does not allow us to pick up custom plates for our customers, so you will be responsible for retrieving the plates once they are ready.  ',
},

       {
        question: 'I still have questions, where do I go for answers? ',
        answer:
'Please do not hesitate to reach out to us through email register@thetitlegirl.com or phone 214.997.6005 and we are happy to answer your questions.',
},

    ],
  }),
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
